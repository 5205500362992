import { Input, Result } from 'antd';
import { egeniePcTheme, RenderByCondition } from 'egenie-common';
import { observer } from 'mobx-react';
import React from 'react';
import { ScanWrapper } from '../../utils';
import styles from './index.less';
import { Store } from './store';

@observer
class Left extends React.Component<{ store: Store; }> {
  render() {
    const {
      weight,
      weightRef,
      handleWeight,
      handleWeightPressEnter,
      courierNo,
      courierNoRef,
      handleCourierNo,
      handleCourierNoPressEnter,
      weightedCount,
      totalCount,
    } = this.props.store;

    return (
      <>
        <h2>
          已称重/待称重发货单
        </h2>
        <div className={styles.count}>
          <a>
            {weightedCount}
          </a>
          <span>
            /
          </span>
          <a>
            {totalCount}
          </a>
        </div>
        <h2 style={{ paddingBottom: egeniePcTheme.spacing['egenie-spacing-sm'] }}>
          包裹重量
        </h2>
        <Input
          allowClear
          autoComplete="off"
          autoFocus
          onChange={handleWeight}
          onPressEnter={(event: any) => handleWeightPressEnter(event.target.value)}
          placeholder="请扫描包裹重量"
          ref={weightRef}
          size="large"
          suffix="千克"
          value={weight}
        />
        <h2 style={{ padding: `${egeniePcTheme.spacing['egenie-spacing-sm']} 0` }}>
          请扫描快递单
        </h2>
        <Input
          allowClear
          autoComplete="off"
          className={styles.input}
          onChange={handleCourierNo}
          onPressEnter={(event: any) => handleCourierNoPressEnter(event.target.value)}
          placeholder="请扫描快递单号"
          ref={courierNoRef}
          size="large"
          value={courierNo}
        />
      </>
    );
  }
}

@observer
class Right extends React.Component<{ store: Store; }> {
  render() {
    const {
      status,
      detailData,
    } = this.props.store;
    return (
      <>
        <RenderByCondition show={status === 'info'}>
          <Result
            status="info"
            title="请将快递包裹放到秤上"
          />
        </RenderByCondition>
        <RenderByCondition show={status === 'success'}>
          <Result
            status="success"
            subTitle={`快递: ${detailData.currentCourierNo || ''} (${detailData.currentCourierName || ''})`}
            title={`重量: ${detailData.weight || ''} 千克`}
          />
        </RenderByCondition>
      </>
    );
  }
}

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <ScanWrapper
      leftChildren={<Left store={store}/>}
      leftTitle="称重"
      leftWidth={400}
      rightChildren={<Right store={store}/>}
      rightTitle="扫描结果"
    />
  );
}

