import type { InputRef } from 'antd';
import type { ResultStatusType } from 'antd/lib/result';
import type { BatchReportData } from 'egenie-common';
import { formatNumber, renderModal, destroyAllModal } from 'egenie-common';
import type { BaseData } from 'egenie-utils';
import { BatchReport, request } from 'egenie-utils';
import { action, computed, observable, set } from 'mobx';
import React from 'react';
import { playVoice, voiceData } from '../../utils';

enum EnumWeightFlag {
  success = 0,
  canceled = 1,
  weighted = 3,
  empty = 4,
  orderError = 5
}

interface DetailData {
  wmsOrderId: number;
  currentCourierNo: string;
  weight: number | string;
  currentCourierName: string;
  hint: EnumWeightFlag;
  weightInfo?: BatchReportData['data'];
}

export class Store {
  constructor() {
    request<BaseData<number>>({ url: '/api/cloud/wms/rest/order/check/weight/count' })
      .then((info) => this.totalCount = formatNumber(info.data));
  }

  @observable public weightedCount = 0;

  @observable public totalCount = 0;

  @observable public weight = '';

  public weightRef: React.RefObject<InputRef> = React.createRef();

  @action public handleWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.weight = event.target.value;
  };

  @action public handleWeightPressEnter = (weight: string) => {
    this.weight = weight || '';
    if (this.courierNoRef.current) {
      this.courierNoRef.current.focus();
    }
  };

  @observable public courierNo = '';

  public courierNoRef: React.RefObject<InputRef> = React.createRef();

  @action public handleCourierNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.courierNo = event.target.value;
  };

  @action public handleCourierNoPressEnter = (courierNo: string) => {
    const weight = formatNumber(this.weight);
    this.courierNo = '';
    this.weight = '';
    if (this.weightRef.current) {
      this.weightRef.current.focus();
    }

    if (weight <= 0 || !courierNo) {
      playVoice(voiceData['请输入重量']);
      return;
    }

    request<BaseData<DetailData>>({
      url: '/api/cloud/wms/rest/order/check/weight/pass',
      method: 'POST',
      data: {
        weight,
        courierNo,
      },
    })
      .then((info) => {
        if (info?.data?.weightInfo) {
          destroyAllModal();
          renderModal(
            <BatchReport
              {...info.data.weightInfo}
              columns={[
                {
                  title: '快递单号',
                  dataIndex: 'orderNo',
                },
                {
                  title: '失败原因',
                  dataIndex: 'operationResult',
                },
              ]}
            />
          );
        }

        set(this.detailData, info.data || {});
        switch (info.data?.hint) {
          case EnumWeightFlag.success:
            playVoice(voiceData['通过']);
            this.weightedCount++;
            break;
          case EnumWeightFlag.canceled:
            playVoice(voiceData['已作废']);
            break;
          case EnumWeightFlag.weighted:
            playVoice(voiceData['已称重']);
            break;
          case EnumWeightFlag.orderError:
            playVoice(voiceData['订单错误']);
            break;
          default:
            playVoice(voiceData['错误']);
            break;
        }
      })
      .catch(() => playVoice(voiceData['错误']));
  };

  @observable public detailData: DetailData = {
    wmsOrderId: null,
    currentCourierNo: '',
    weight: '',
    currentCourierName: '',
    hint: null,
  };

  @computed
  public get status(): ResultStatusType {
    if (this.detailData && this.detailData.hint === EnumWeightFlag.success) {
      return 'success';
    } else {
      return 'info';
    }
  }
}
